var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0 ma-0",attrs:{"fluid":""}},[_c('p',{staticClass:"text-h6"},[_vm._v("Program overview by course")]),_c('div',[_c('table',{attrs:{"cellpadding":"0","cellspacing":"0"},on:{"mouseover":_vm.highlight,"mouseleave":_vm.highlightStop}},[_c('tr',{staticClass:"semester",class:{ active: 0 === _vm.currentRow }},[_c('th',{class:{ active: 0 === _vm.currentColl }}),_vm._l((_vm.headers),function(course,_ci){return _c('th',{key:course.code,class:{
            'pb-2': true,
            'pt-2': true,
            'tip-activator': true,
            active: _ci === _vm.currentColl || _ci === _vm.currentColl - 1,
          },attrs:{"data-coll":_ci}},[_c('v-tooltip',{attrs:{"right":"","color":"#f1f58f","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"tip-activator"},'div',attrs,false),on),[_vm._v(" "+_vm._s(course.code)+" ")])]}}],null,true)},[_c('div',{staticClass:"tip-text"},[_vm._v(_vm._s(course.name))])])],1)})],2),_vm._l((_vm.outcomesFiltered),function(out,_ri){return _c('tr',{key:out.id,class:{ active: _ri === _vm.currentRow || _ri === _vm.currentRow - 1 },attrs:{"data-row":_ri}},[_c('td',{class:{
            'outcomes-name': true,
            active: 0 === _vm.currentColl,
            [_vm.$getOutcomeTypeById(out.id)]: true,
          }},[_c('v-tooltip',{attrs:{"bottom":"","color":"#f1f58f","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"tip-activator pl-2 pr-4"},'div',attrs,false),on),[_vm._v(" "+_vm._s(out.id)+" ")])]}}],null,true)},[_c('div',{staticClass:"tip-text"},[_vm._v(_vm._s(out.id)+": "+_vm._s(out.name))])])],1),_vm._l((_vm.semestersIndex),function(semIndex,_ci){return _c('td',{key:semIndex,class:{
            outcomes: true,
            active: _ci === _vm.currentColl || _ci === _vm.currentColl - 1,
          },attrs:{"data-coll":_ci}},[_c('div',{staticClass:"outcomes-values"},[(_vm.semesters[semIndex].outcomes[out.id])?_vm._l((_vm.semesters[semIndex].outcomes[out.id]),function(ov,i){return _c('div',{key:i,class:{
                  'outcomes-value': true,
                  ['outcomes-value-' + i]: ov.length > 0,
                }},[(ov.length > 0)?[_c('v-tooltip',{attrs:{"open-delay":"500","bottom":"","color":"#f1f58f","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"tip-activator"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.hideValues ? "" : ov[0].outcomes.length)+" ")])]}}],null,true)},[_c('div',{staticClass:"tip-text"},[_c('table',[_c('tr',[_c('td',[_vm._v("["+_vm._s(semIndex)+"]")]),_c('td',[_vm._v(_vm._s(ov[0].name))])])]),_vm._v(" Course learning outcomes that map to "+_vm._s(out.id)+" level "+_vm._s(i + 1)+": "),_c('v-divider',{staticClass:"mt-2 mb-2",attrs:{"color":"white"}}),_c('table',_vm._l((ov[0].outcomes),function(c,ij){return _c('tr',{key:ij},[_c('td',[_vm._v("■")]),_c('td',[_vm._v(_vm._s(c.id)+" - "+_vm._s(c.name))])])}),0)],1)])]:_vm._e()],2)}):_vm._e()],2)])})],2)})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }