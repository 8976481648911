<template>
  <v-layout>
    <v-navigation-drawer permanent absolute>
      <v-card
        flat
        tile
        color="primary"
        dark
        style="position: sticky; top: 0px; z-index: 1001"
      >
        <v-card-title>UiB</v-card-title>
        <v-card-subtitle class="text-h6">Curriculum mapping</v-card-subtitle>
        <v-card-text>Det Psykologiske Fakultet</v-card-text>
      </v-card>
      <table class="filters ma-2" style="z-index: 1000">
        <tr>
          <td>
            <v-radio-group
              class="pt-0 mt-0"
              x-small
              dense
              hide-details
              v-model="view"
              label="Type of overview"
            >
              <v-radio
                class="pb-0 mb-0 pb-2"
                value="semester"
                dense
                hide-details
                label="Semester"
                x-small
              >
                <template v-slot:label
                  ><v-btn
                    x-small
                    text
                    dense
                    class="text-xs-caption button-neutral"
                  >
                    Program overview – Semester
                  </v-btn></template
                >
              </v-radio>

              <v-radio value="courses" dense hide-details label="Kurs" x-small>
                <template v-slot:label
                  ><v-btn
                    x-small
                    text
                    dense
                    class="text-xs-caption button-neutral"
                  >
                    Program overview – Course
                  </v-btn></template
                >
              </v-radio>

              <v-radio
                value="rawmap"
                dense
                hide-details
                label="Rå kartlegging"
                x-small
              >
                <template v-slot:label
                  ><v-btn
                    x-small
                    text
                    dense
                    class="text-xs-caption button-neutral"
                  >
                    Detailed course overview
                  </v-btn></template
                >
              </v-radio>
            </v-radio-group>
            <v-divider class="mt-6 mb-2" />
          </td>
        </tr>

        <tr>
          <td>
            <div
              id="course-select"
              style="background-color: red; position: absolute"
            ></div>
            <v-select
              attach="#course-select"
              v-bind:key="coursesSelectKey"
              dense
              v-model="coursesModel"
              single-line
              :multiple="courseMultiple"
              chips
              small-chips
              item-value="course_code"
              item-text="course_name"
              :items="coursesSorted"
              label="Valg av kurs"
              hide-details
              class="pa-0 ma-0 text-caption courses-select"
              :clearable="courseMultiple && coursesModel.length > 1"
              :menu-props="{ bottom: true }"
              x-small
            >
              <template v-slot:item="{ item }">
                <span class="text-caption">{{ item.vname }}</span>
              </template>
              <template v-slot:prepend-item v-if="courseMultiple">
                <v-list-item
                  dense
                  ripple
                  @mousedown.prevent
                  style="
                    position: sticky;
                    top: 0;
                    z-index: 1000;
                    background-color: white;
                  "
                >
                  <v-list-item-action>
                    <v-icon
                      @click="toggle"
                      label="123"
                      :color="coursesModel.length > 0 ? 'indigo darken-4' : ''"
                    >
                      {{ icon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <!-- <v-list-item-title> Select All </v-list-item-title> -->
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon
                      @click="updateSort('chrono')"
                      :color="sortBy === 'chrono' ? 'indigo darken-4' : ''"
                    >
                      mdi-sort-clock-ascending
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-action>
                    <v-icon
                      @click="updateSort('abc')"
                      :color="sortBy === 'abc' ? 'indigo darken-4' : ''"
                    >
                      mdi-sort-alphabetical-ascending
                    </v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-tooltip
                  bottom
                  color="primary"
                  content-class="custom-tooltip"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-bind="attrs"
                      v-on="on"
                      small
                      class="mb-1 text-caption course-chip"
                      v-if="index < 10"
                      :close="courseMultiple"
                      label
                      @click:close="
                        coursesModel = coursesModel.filter(
                          (e) => e !== item.course_code
                        )
                      "
                    >
                      <span>{{ item.course_code }}</span>
                    </v-chip>
                  </template>
                  <div class="tip-text">{{ item.course_name }}</div>
                </v-tooltip>
                <span v-if="index === 10" class="grey--text text-caption">
                  (+{{ coursesModel.length - 10 }} others)
                </span>
              </template>
            </v-select>
            <v-divider class="mt-3 mb-2" style="opacity: 0" />
          </td>
        </tr>

        <tr>
          <td>
            <v-radio-group
              class="pt-0 mt-0"
              x-small
              dense
              hide-details
              label="Program learning outcomes"
            >
              <v-checkbox
                v-for="cat in categories"
                v-bind:key="cat.key"
                dense
                v-model="cat.value"
                :label="cat.label"
                hide-details
                x-small
                class="pa-0 ma-0 pb-2"
              >
                <template v-slot:label
                  ><v-btn
                    x-small
                    text
                    dense
                    :class="['text-caption', 'button-neutral', cat.class || '']"
                  >
                    {{ cat.label }}
                  </v-btn></template
                >
              </v-checkbox>
            </v-radio-group>
            <v-divider class="mt-2 mb-2" />
          </td>
        </tr>

        <tr>
          <td>
            <v-radio-group
              class="pt-0 mt-0"
              x-small
              dense
              hide-details
              label="Compentence levels"
            >
              <v-checkbox
                v-for="level in levels"
                v-model="level.model"
                hide-details
                dense
                v-bind:key="level.value"
                x-small
                class="pa-0 ma-0 pb-2"
              >
                <template v-slot:label>
                  <v-btn x-small text :class="['text-caption', level.class]">
                    {{ level.label }}
                  </v-btn>
                </template>
              </v-checkbox>
            </v-radio-group>
            <v-divider class="mt-2 mb-2" />
          </td>
        </tr>
      </table>
    </v-navigation-drawer>
    <v-main>
      <v-container fluid>
        <div class="chart-main ma-6">
          <chart-semester
            v-if="view === 'semester'"
            :categories="categories"
            :coursesKeys="coursesModel"
            :levels="levelsKeys"
            :hideValues="hideValues"
          />
          <chart-courses
            v-if="view === 'courses'"
            :categories="categories"
            :coursesKeys="coursesModel"
            :levels="levelsKeys"
            :hideValues="hideValues"
          />
          <chart-rawmap
            v-if="view === 'rawmap'"
            :categories="categories"
            :coursesKeys="[coursesModel]"
            :levels="levelsKeys"
          />
          <v-fab-transition>
            <v-btn
              v-show="courseMultiple && showValues"
              @click="hideValues = !hideValues"
              :color="hideValues ? 'grey' : 'primary'"
              fab
              dark
              small
              fixed
              bottom
              right
              ><v-icon>{{ hideValues ? "mdi-eye-off" : "mdi-eye" }}</v-icon>
            </v-btn>
          </v-fab-transition>
        </div>
      </v-container>
    </v-main>
  </v-layout>
</template>

<script>
import ChartSemester from "../components/ChartSemester";
import ChartCourses from "../components/ChartCourses";
import ChartRawmap from "../components/ChartRawmap";

export default {
  name: "HomeVew",
  components: {
    ChartSemester,
    ChartCourses,
    ChartRawmap,
  },
  mounted() {
    this.$api.get("/courses").then((res) => {
      this.courses = res.data.courses || [];
      this.coursesSorted = JSON.parse(JSON.stringify(this.courses));
      this.coursesSorted.forEach((course) => {
        course.vname = "[" + course.course_code + "] " + course.course_name;
      });
      /**
      this.coursesSorted.sort((a, b) => {
        if (a.course_name.toLowerCase() < b.course_name.toLowerCase())
          return -1;
        if (a.course_name.toLowerCase() > b.course_name.toLowerCase()) return 1;
        return 0;
      });
      **/
    });
  },
  data: () => ({
    courses: [],
    coursesSorted: [],
    hideValues: true,
    levelsKeys: [0, 1, 2],
    courseMultiple: true,
    showValues: false,
    sortBy: "chrono",
    levels: [
      {
        model: true,
        value: 0,
        label: "Beginner",
        class: "outcomes-value-0",
      },
      {
        model: true,
        value: 1,
        label: "Intermediate",
        class: "outcomes-value-1",
      },
      {
        model: true,
        value: 2,
        label: "Advanced",
        class: "outcomes-value-2",
      },
    ],
    categories: [
      {
        key: "knowledge",
        pattern: "^K",
        label: "Kunnskapar",
        value: true,
        class: "outcomes-type-knowledge",
      },
      {
        key: "skills",
        pattern: "^F",
        label: "Ferdigheiter",
        value: true,
        class: "outcomes-type-skills",
      },
      {
        key: "competences",
        pattern: "^GK",
        label: "Generell kompetanse",
        value: true,
        class: "outcomes-type-competences",
      },
    ],
    view: "courses",
    coursesSelectKey: 0,
    coursesSelectTimeout: null,
    coursesModel: [],
  }),
  computed: {
    icon() {
      if (this.courses.length === this.coursesModel.length)
        return "mdi-close-box";
      if (this.coursesModel.length > 0) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  watch: {
    view(val, prev) {
      if (prev === "rawmap") {
        this.courseMultiple = true;
        this.coursesModel = [];
      }
      if (val === "rawmap") {
        this.courseMultiple = false;
        this.coursesModel = this.courses[0].course_code;
      }
    },
    levels: {
      deep: true,
      handler(val) {
        this.levelsKeys = val.filter((e) => e.model).map((e) => e.value);
        if (this.levelsKeys.length === 0) {
          this.$nextTick(() => {
            this.levels.forEach((level) => (level.model = true));
          });
        }
      },
    },
    coursesModel: {
      deep: true,
      // eslint-disable-next-line no-unused-vars
      handler(val) {
        /**
        if (val.length === 0) this.coursesSelectKey++;
        if (val.length > 0) {
          clearTimeout(this.coursesSelectTimeout);
          this.coursesSelectTimeout = setTimeout(() => {
            this.coursesSelectKey++;
          }, 3000);
        } **/
      },
    },
    categories: {
      deep: true,
      handler(val) {
        const selectCount = val.filter((e) => e.value).length;
        if (selectCount === 0) {
          this.$nextTick(() => {
            this.categories.forEach((cat) => {
              cat.value = true;
            });
          });
        }
      },
    },
  },
  methods: {
    updateSort(val) {
      this.$nextTick(() => {
        if (val === "chrono") {
          this.sortBy = "chrono";

          this.coursesSorted = JSON.parse(JSON.stringify(this.courses));
          this.coursesSorted.forEach((course) => {
            course.vname = "[" + course.course_code + "] " + course.course_name;
          });
        } else {
          this.sortBy = "abc";

          this.coursesSorted.sort((a, b) => {
            if (a.course_name.toLowerCase() < b.course_name.toLowerCase())
              return -1;
            if (a.course_name.toLowerCase() > b.course_name.toLowerCase())
              return 1;
            return 0;
          });
        }
      });
    },
    toggle() {
      this.$nextTick(() => {
        if (this.coursesModel.length === this.courses.length) {
          this.coursesModel = [];
        } else {
          this.coursesModel = this.courses.map((e) => e.course_code);
        }
      });
    },
  },
};
</script>

<style scoped>
.courses-select > * {
  font-size: 12px !important;
}
.course-chip {
  height: 20px !important;
}
.button-neutral {
  background-color: rgba(0, 0, 0, 0.15);
}
.outcomes-value-0 {
  background-color: rgb(160, 218, 57);
}
.outcomes-value-1 {
  background-color: rgb(39, 127, 142);
  color: #fff;
}
.outcomes-value-2 {
  background-color: rgb(70, 49, 127);
  color: #fff;
}
.filters td {
  vertical-align: top;
  padding-top: 6px;
  padding-right: 48px;
  padding-bottom: 6px;
}
.chart-main {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 256px;
  overflow: scroll;
}
* {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.v-list-item {
  line-height: 16px !important;
  min-height: 16px !important;
}
.v-list-item span {
  line-height: 16px !important;
  min-height: 16px !important;
}
</style>
<style>
.v-list-item {
  line-height: normal !important;
  min-height: normal !important;
}
.v-list-item span {
  line-height: normal !important;
  min-height: normal !important;
  padding-top: 8px;
  padding-bottom: 8px;
}
.outcomes-type-knowledge {
  background-color: #a9cbb7 !important;
}
.outcomes-type-skills {
  background-color: #f7ff58 !important;
}
.outcomes-type-competences {
  background-color: #ff934f !important;
}
</style>
