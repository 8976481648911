<template>
  <v-app>
    <!--
    <v-app-bar dense flat app color="primary" dark>
      <v-app-bar-title
        >UiB / Curriculum mapping / Det Psykologiske Fakultet</v-app-bar-title
      >

      <v-spacer></v-spacer>
    </v-app-bar>
    -->
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
