<template>
  <v-container fluid class="pa-0 ma-0">
    <p class="text-h6">Detailed course overview of {{ course.course_code }}</p>
    <div>
      <table
        cellpadding="0"
        cellspacing="0"
        @mouseover="highlight"
        @mouseleave="highlightStop"
      >
        <tr class="semester" :class="{ active: 0 === currentRow }">
          <th :class="{ active: 0 === currentColl }"></th>
          <th
            v-for="(cout, _ci) in headers"
            v-bind:key="cout.id"
            :class="{
              'pb-2': true,
              'pt-2': true,
              'tip-activator': true,
              active: _ci === currentColl || _ci === currentColl - 1,
            }"
            :data-coll="_ci"
          >
            <v-tooltip right color="#f1f58f" content-class="custom-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <div class="tip-activator pl-1 pr-1" v-bind="attrs" v-on="on">
                  {{ cout.id }}
                </div>
              </template>
              <div class="tip-text">{{ cout.id }} - {{ cout.name }}</div>
            </v-tooltip>
          </th>
        </tr>
        <tr
          v-for="(out, _ri) in outcomesFiltered"
          v-bind:key="out.id"
          :class="{ active: _ri === currentRow || _ri === currentRow - 1 }"
          :data-row="_ri"
        >
          <td
            :class="{
              'outcomes-name': true,
              active: 0 === currentColl,
              [$getOutcomeTypeById(out.id)]: true,
            }"
          >
            <v-tooltip bottom color="#f1f58f" content-class="custom-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <div class="tip-activator pl-2 pr-4" v-bind="attrs" v-on="on">
                  {{ out.id }}
                </div>
              </template>
              <div class="tip-text">{{ out.id }}: {{ out.name }}</div>
            </v-tooltip>
          </td>
          <td
            :class="{
              outcomes: true,
              active: _ci === currentColl || _ci === currentColl - 1,
            }"
            v-for="(cout, _ci) in headers"
            v-bind:key="cout.id"
            :data-coll="_ci"
          >
            <div
              v-if="cout[out.id] && levels.indexOf(cout[out.id] - 1) !== -1"
              :class="{
                ['outcomes-value-' + (cout[out.id] - 1)]: true,
              }"
            >
              <v-tooltip
                open-delay="500"
                bottom
                color="#f1f58f"
                content-class="custom-tooltip"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="tip-activator" v-bind="attrs" v-on="on">
                    &nbsp;
                  </div>
                </template>
                <div class="tip-text">
                  <table>
                    <tr>
                      <td>[{{ course.course_code }}]</td>
                      <td>{{ course.course_name }}</td>
                    </tr>
                  </table>
                  <!--
                  <v-divider color="white" class="mt-2 mb-2" />
                  <table>
                    <tr v-bind:key="ij" v-for="(c, ij) in ov[0].outcomes">
                      <td>&#9632;</td>
                      <td>{{ c.id }} - {{ c.name }}</td>
                    </tr>
                  </table> -->
                  {{ out.id }} is covered at level {{ cout[out.id] }} by
                  {{ cout.id }}:
                  <!-- this
                        <span v-if="ov.length > 1"
                          >{{ ov.length }} courses:</span
                        >
                        <span v-else>course:</span> -->
                  <v-divider color="white" class="mt-2 mb-2" />
                  <table>
                    <tr>
                      <td>&#9632;</td>
                      <td>{{ cout.name }}</td>
                    </tr>
                  </table>
                </div>
              </v-tooltip>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "ChartRawmap",

  props: {
    categories: Array,
    coursesKeys: Array,
    levels: Array,
  },

  data: () => ({
    course: {},
    courses: [],
    outcomes: [],
    outcomesFiltered: [],
    headers: [],

    currentRow: -1,
    currentColl: -1,
  }),

  watch: {
    levels: {
      deep: true,
      handler() {
        this.update();
      },
    },
    coursesKeys: {
      deep: true,
      handler() {
        this.update();
      },
    },
    categories: {
      deep: true,
      handler() {
        this.update();
      },
    },
  },

  methods: {
    highlightStop() {
      this.currentRow = -1;
      this.currentColl = -1;
    },
    highlight(event) {
      const TR = event.path.filter((e) => e.nodeName === "TR")[0];
      if (TR && TR?.dataset?.row) {
        this.currentRow = Number.parseInt(TR.dataset.row);
      } else {
        this.currentRow = -1;
      }

      const TD = event.path.filter(
        (e) => e.nodeName === "TD" || e.nodeName === "TH"
      )[0];
      if (TD && TD?.dataset?.coll) {
        this.currentColl = Number.parseInt(TD.dataset.coll);
      } else {
        this.currentColl = -1;
      }
    },
    update() {
      const categoriesPatterns = new RegExp(
        this.categories
          .filter((e) => e.value)
          .map((e) => e.pattern)
          .join("|")
      );
      this.outcomesFiltered = this.outcomes.filter((out) =>
        out.id.match(categoriesPatterns)
      );

      const course = this.courses.filter((course) => {
        if (
          this.coursesKeys.length !== 0 &&
          this.coursesKeys.indexOf(course.course_code) === -1
        )
          return false;
        return true;
      })[0];

      const map_outcomes = {};
      course.outcomes.forEach((outcome) => {
        map_outcomes[outcome.id] = Object.assign(
          map_outcomes[outcome.id] || {},
          outcome
        );
      });
      this.headers = Object.values(map_outcomes);
      this.course = course;
    },
  },

  mounted() {
    this.$api
      .get("/view/semester")
      .then((res) => {
        this.courses = res.data.courses;
        this.outcomes = res.data.outcomes;
        this.update();
      })
      .catch(() => {
        // console.log(error);
        this.courses = [];
        this.outcomes = [];
        this.update();
      });
  },
};
</script>

<style scoped>
/** https://css-tricks.com/a-table-with-both-a-sticky-header-and-a-sticky-first-column/ */
table {
  border-collapse: collapse;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.75);
}

th {
  font-size: 8px;
}

th,
td {
  border: 1px solid rgba(0, 0, 0, 0.15);
}

tr.active td {
  border-bottom: 1px solid rgb(255, 47, 0) !important;
}
tr.active th {
  border-bottom: 1px solid rgb(255, 47, 0) !important;
}
td.active {
  border-right: 1px solid rgb(255, 47, 0) !important;
}
th.active {
  border-right: 1px solid rgb(255, 47, 0) !important;
}

tr.semester td:not(:first-child) {
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  text-align: center;
  font-weight: bold;
}
td.outcomes-name {
  text-align: left;
  vertical-align: middle;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  font-weight: bold;
}
td.outcomes {
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
div.outcomes-values {
  width: 54px;
  height: 18px;
}
div.outcomes-value {
  width: 18px;
  height: 18px;
  float: left;
  line-height: 18px;
  text-align: center;
}
div.tip-activator {
  width: 100%;
  height: 18px;
  line-height: 18px;
}
.tip-activator:hover {
  background-color: rgb(222, 235, 247);
  cursor: pointer;
}

div.outcomes-value-0 {
  background-color: rgb(160, 218, 57);
}
div.outcomes-value-1 {
  background-color: rgb(39, 127, 142);
  color: #fff;
}
div.outcomes-value-2 {
  background-color: rgb(70, 49, 127);
  color: #fff;
}

.custom-tooltip {
  opacity: 1 !important;
}

div.tip-text {
  max-width: 300px;
  font-size: 12px;
  color: black;
  padding-top: 8px;
  padding-bottom: 8px;
}
div.tip-text td {
  padding-right: 4px;
  padding-bottom: 4px;
  vertical-align: top;
  border: none;
}
</style>
