var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0 ma-0",attrs:{"fluid":""}},[_c('p',{staticClass:"text-h6"},[_vm._v("Detailed course overview of "+_vm._s(_vm.course.course_code))]),_c('div',[_c('table',{attrs:{"cellpadding":"0","cellspacing":"0"},on:{"mouseover":_vm.highlight,"mouseleave":_vm.highlightStop}},[_c('tr',{staticClass:"semester",class:{ active: 0 === _vm.currentRow }},[_c('th',{class:{ active: 0 === _vm.currentColl }}),_vm._l((_vm.headers),function(cout,_ci){return _c('th',{key:cout.id,class:{
            'pb-2': true,
            'pt-2': true,
            'tip-activator': true,
            active: _ci === _vm.currentColl || _ci === _vm.currentColl - 1,
          },attrs:{"data-coll":_ci}},[_c('v-tooltip',{attrs:{"right":"","color":"#f1f58f","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"tip-activator pl-1 pr-1"},'div',attrs,false),on),[_vm._v(" "+_vm._s(cout.id)+" ")])]}}],null,true)},[_c('div',{staticClass:"tip-text"},[_vm._v(_vm._s(cout.id)+" - "+_vm._s(cout.name))])])],1)})],2),_vm._l((_vm.outcomesFiltered),function(out,_ri){return _c('tr',{key:out.id,class:{ active: _ri === _vm.currentRow || _ri === _vm.currentRow - 1 },attrs:{"data-row":_ri}},[_c('td',{class:{
            'outcomes-name': true,
            active: 0 === _vm.currentColl,
            [_vm.$getOutcomeTypeById(out.id)]: true,
          }},[_c('v-tooltip',{attrs:{"bottom":"","color":"#f1f58f","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"tip-activator pl-2 pr-4"},'div',attrs,false),on),[_vm._v(" "+_vm._s(out.id)+" ")])]}}],null,true)},[_c('div',{staticClass:"tip-text"},[_vm._v(_vm._s(out.id)+": "+_vm._s(out.name))])])],1),_vm._l((_vm.headers),function(cout,_ci){return _c('td',{key:cout.id,class:{
            outcomes: true,
            active: _ci === _vm.currentColl || _ci === _vm.currentColl - 1,
          },attrs:{"data-coll":_ci}},[(cout[out.id] && _vm.levels.indexOf(cout[out.id] - 1) !== -1)?_c('div',{class:{
              ['outcomes-value-' + (cout[out.id] - 1)]: true,
            }},[_c('v-tooltip',{attrs:{"open-delay":"500","bottom":"","color":"#f1f58f","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"tip-activator"},'div',attrs,false),on),[_vm._v("   ")])]}}],null,true)},[_c('div',{staticClass:"tip-text"},[_c('table',[_c('tr',[_c('td',[_vm._v("["+_vm._s(_vm.course.course_code)+"]")]),_c('td',[_vm._v(_vm._s(_vm.course.course_name))])])]),_vm._v(" "+_vm._s(out.id)+" is covered at level "+_vm._s(cout[out.id])+" by "+_vm._s(cout.id)+": "),_c('v-divider',{staticClass:"mt-2 mb-2",attrs:{"color":"white"}}),_c('table',[_c('tr',[_c('td',[_vm._v("■")]),_c('td',[_vm._v(_vm._s(cout.name))])])])],1)])],1):_vm._e()])})],2)})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }