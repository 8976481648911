import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import axios from "axios";
import vuetify from "./plugins/vuetify";

Vue.prototype.$api = axios.create({
  baseURL: "/api",
  withCredentials: true,
});

Vue.prototype.$getOutcomeTypeById = (id) => {
  const key = ((id || "")[0] || "").toLowerCase();
  switch (key) {
    case "k":
      return "outcomes-type-knowledge";
    case "f":
      return "outcomes-type-skills";
    case "g":
      return "outcomes-type-competences";
  }
  return "";
};

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
